export default function NWCIcon(props: { width?: number; height?: number }) {
  return (
    <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M110.605 31.0659C100.371 20.871 83.7516 20.9801 73.5543 31.2111L7.5804 97.4161C-2.61818 107.648 -2.48842 123.976 7.74253 134.172L115.913 242.342C126.146 252.536 142.733 252.451 152.93 242.22L185.082 210.068C175.705 219.445 167.989 212.703 158.845 203.597L140.911 185.664C127.297 191.053 111.385 188.376 100.355 177.367L86.6891 163.701C86.2293 163.245 85.8745 162.769 85.6252 162.172C85.3759 161.574 85.2473 160.933 85.2467 160.286C85.2462 159.638 85.3737 158.997 85.622 158.4C85.8702 157.802 86.2593 157.295 86.7183 156.839L94.4518 149.105L77.6167 132.27C74.9814 129.64 74.5511 125.393 76.9077 122.524C79.6015 119.23 84.4857 119.055 87.4411 122.004L104.504 139.053L116.061 127.496L99.1857 110.663C96.5492 108.032 96.1201 103.786 98.4968 100.897C99.1272 100.13 99.9112 99.5026 100.798 99.0561C101.686 98.6097 102.656 98.3537 103.648 98.3048C104.64 98.2558 105.631 98.4148 106.558 98.7717C107.485 99.1285 108.327 99.6753 109.03 100.377L126.162 117.395L133.422 110.134C133.878 109.675 134.327 109.261 134.925 109.012C135.522 108.763 136.163 108.634 136.811 108.634C137.458 108.633 138.099 108.76 138.697 109.008C139.295 109.256 139.838 109.62 140.295 110.079L153.983 123.74C164.875 134.611 167.723 150.433 162.631 163.945L180.568 181.882C189.712 190.987 197.363 197.787 206.741 188.41L247.312 147.838C237.56 157.59 229.548 150.077 219.911 140.448L110.605 31.0659Z"
        fill="url(#paint0_linear_4_519)"
      />
      <path
        d="M187.308 13.0273L152.82 47.4873L229.448 124.116C236.783 131.419 243.158 137.239 250.232 134.417C254.321 132.787 257.128 128.351 255.561 124.238C218.894 28.0253 218.879 28.0238 214.015 17.507C209.151 6.99014 195.471 4.76016 187.308 13.0273Z"
        fill="#897FFF"
      />
      <path
        d="M187.308 13.0273L152.82 47.4873L229.448 124.116C236.783 131.419 243.158 137.239 250.232 134.417C254.321 132.787 257.128 128.351 255.561 124.238C218.894 28.0253 218.879 28.0238 214.015 17.507C209.151 6.99014 195.471 4.76016 187.308 13.0273Z"
        fill="#897FFF"
      />
      <path
        d="M187.308 13.0273L152.82 47.4873L229.448 124.116C236.783 131.419 243.158 137.239 250.232 134.417C254.321 132.787 257.128 128.351 255.561 124.238C218.894 28.0253 218.879 28.0238 214.015 17.507C209.151 6.99014 195.471 4.76016 187.308 13.0273Z"
        fill="#897FFF"
      />
      <path
        d="M187.308 13.0273L152.82 47.4873L229.448 124.116C236.783 131.419 243.158 137.239 250.232 134.417C254.321 132.787 257.128 128.351 255.561 124.238C218.894 28.0253 218.879 28.0238 214.015 17.507C209.151 6.99014 195.471 4.76016 187.308 13.0273Z"
        fill="#897FFF"
      />
      <path
        d="M187.308 13.0273L152.82 47.4873L229.448 124.116C236.783 131.419 243.158 137.239 250.232 134.417C254.321 132.787 257.128 128.351 255.561 124.238C218.894 28.0253 218.879 28.0238 214.015 17.507C209.151 6.99014 195.471 4.76016 187.308 13.0273Z"
        fill="#897FFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4_519"
          x1="123.656"
          y1="10.4403"
          x2="123.656"
          y2="249.941"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCA4A" />
          <stop offset="1" stopColor="#F7931A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
