import { defineMessages } from "react-intl";

export default defineMessages({
  Login: { defaultMessage: "Login", id: "AyGauy" },
  Global: { defaultMessage: "Global", id: "EWyQH5" },
  NewUsers: { defaultMessage: "New users page", id: "NndBJE" },
  Reactions: { defaultMessage: "Reactions", id: "XgWvGA" },
  Followers: { defaultMessage: "Followers", id: "pzTOmv" },
  FollowersCount: { defaultMessage: "{n} Followers", id: "3tVy+Z" },
  Follows: { defaultMessage: "Following", id: "cPIKU2" },
  FollowsCount: { defaultMessage: "{n} Following", id: "1nYUGC" },
  Zaps: { defaultMessage: "Zaps", id: "OEW7yJ" },
  ZapsCount: { defaultMessage: "{n} Zaps", id: "FDguSC" },
  Muted: { defaultMessage: "Muted", id: "HOzFdo" },
  MutedCount: { defaultMessage: "{n} Muted", id: "CmZ9ls" },
  Blocked: { defaultMessage: "Blocked", id: "qUJTsT" },
  BlockedCount: { defaultMessage: "{n} Blocked", id: "W2PiAr" },
  Sats: { defaultMessage: "{n} {n, plural, =1 {sat} other {sats}}", id: "jA3OE/" },
  Following: { defaultMessage: "Following {n}", id: "lnaT9F" },
  Settings: { defaultMessage: "Settings", id: "D3idYv" },
  Messages: { defaultMessage: "Messages", id: "hMzcSq" },
  MarkAllRead: { defaultMessage: "Mark All Read", id: "e7qqly" },
  GetVerified: { defaultMessage: "Get Verified", id: "eJj8HD" },
  Nip05: {
    defaultMessage: `NIP-05 is a DNS based verification spec which helps to validate you as a real user.`,
    id: "vZ4quW",
  },
  Nip05Pros: { defaultMessage: `Getting NIP-05 verified can help:`, id: "thnRpU" },
  AvoidImpersonators: { defaultMessage: "Prevent fake accounts from imitating you", id: "iGT1eE" },
  EasierToFind: { defaultMessage: "Make your profile easier to find and share", id: "/d6vEc" },
  Funding: { defaultMessage: "Fund developers and platforms providing NIP-05 verification services", id: "a5UPxh" },
  SnortSocialNip: {
    defaultMessage: `Our very own NIP-05 verification service, help support the development of this site and get a shiny special badge on our site!`,
    id: "k7sKNy",
  },
  NostrPlebsNip: {
    defaultMessage: `Nostr Plebs is one of the first NIP-05 providers in the space and offers a good collection of domains at reasonable prices`,
    id: "4Vmpt4",
  },
  Relays: {
    defaultMessage: "Relays",
    id: "RoOyAh",
  },
  RelaysCount: {
    defaultMessage: "{n} Relays",
    id: "QDFTjG",
  },
  Bookmarks: { defaultMessage: "Bookmarks", id: "nGBrvw" },
  BookmarksCount: { defaultMessage: "{n} Bookmarks", id: "2a2YiP" },
  KeyPlaceholder: { defaultMessage: "nsec, npub, nip-05, hex", id: "B6H7eJ" },
});
